import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import gtagEvent from 'libs/gtagEvent';
import { WellbeingBenefit } from 'types/wellbeingBenefits';

const WellbeingPage: React.FC = () => {
    const [benefits, setBenefits] = useState<WellbeingBenefit[]>([]);

    useEffect(() => {
        benefitGet();
    }, []);

    const [benefitGetState, benefitGet] = useAsyncFn(async () => {
        const memberid = getMembershipId();
        const access_token = getAccessToken();
        try {
            if (!memberid) {
                throw new Error('Member ID not found');
            }
            const response = await api
                .website(CONFIG, access_token)
                .wellbeingBenefits(memberid);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                setBenefits(response);
            }
        } catch (error) {
            console.log({ benefitGetState });
            console.log('ERROR');
            console.log(error);
        }
    });

    return (
        <main className="container-fluid p-4 wellbeing-page">
            <div className="row">
                <div className="container">
                    <div className="row flex-wrap">
                        <div className="col-12 py-5 col-md-10 offset-md-1">
                            <h1>Member Wellbeing Benefits</h1>
                            <h2>
                                We&apos;re so excited to introduce a boutique
                                series of exclusive wellbeing benefits to help
                                you live your best life.
                            </h2>
                            <p className="text-center">
                                We exist to service our members and connecting
                                our members with great discounts on brands close
                                to your hearts is yet another way we deliver
                                great value to you. All of the incredible
                                businesses we have partnered with are
                                Queensland-born businesses, just like Union
                                Health.
                            </p>
                        </div>
                        <div className="w-100"></div>
                        {benefits.length > 0 &&
                            benefits.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="col-12 col-sm-6 col-md-4 item">
                                        <div className="inner">
                                            <div className="display-image">
                                                <img
                                                    src={
                                                        item?.display_image?.url
                                                    }
                                                    className="img-fluid"
                                                    alt={
                                                        item?.display_image?.alt
                                                    }
                                                />
                                            </div>
                                            <div className="title">
                                                <h3>{item.title}</h3>
                                            </div>
                                            <div
                                                className="body"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.member_body,
                                                }}
                                                onClick={() => {
                                                    gtagEvent({
                                                        screen: 'wellbeing',
                                                        action: `referralfor_${item.title
                                                            .toLowerCase()
                                                            .replace(
                                                                ' ',
                                                                '_',
                                                            )}`,
                                                        label: `User clicked on the benefit card for ${item.title}`,
                                                    });
                                                    gtagEvent({
                                                        screen: 'wellbeing',
                                                        action: `referral_clicked`,
                                                        label: `User clicked on a wellbeing referral link.`,
                                                    });
                                                }}></div>
                                            <div className="logo">
                                                <img
                                                    src={item?.logo?.url}
                                                    className="img-fluid"
                                                    alt={item?.logo?.alt}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WellbeingPage;
